import Constants from 'Constants'
import Common from '@/assets/js/common.js'

var TiersMixin = {
	data() {
		return {

		}
	},
	methods: {
		createTiersClientArea: async function(user) {
			const params = {
				mail: user.email,
				lastname: user.name,
				firstname: user.firstname,
				phone: {
					phone_numero: user.phone,
					phone_country: user.country,
					phone_type: "fixe"
				}
			}

			await this.axios.post(Constants.TIERS_URL, params).then(async response => {
                if(response.data.code_retour === 0) {
                    return response.data.retour
                }
            })
		},

		loadTiers: async function() {
			const url = Constants.TIERS_URL + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::getContractByTiers", url)
            .catch(error => {
                console.error("HorseMixin::loadTiers => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
		}
	}
}

export default TiersMixin
