import Constants from 'Constants'
import Common from '@/assets/js/common.js'

let ContractMixin = {
	methods: {
		getContractByTiers: async function() {
            const url = this.constructRoute(Constants.CONTRACT_TIERS_URL, {contract_id: ''}) + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContractByTiers", url)
            .catch(error => {
                console.error("ContractMixin::getContractByTiers => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
		},

        getContractByTiersAndHorse: async function(horse_id) {
            const url = this.constructRoute(Constants.CONTRACT_TIERS_HORSE_URL, {horse_id}) +'?licence_key='+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContractByTiersAndHorse", url)
            .catch(error => {
                console.error("ContractMixin::getContractByTiersAndHorse => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        addContract: async function() {
            const url = this.constructRoute(Constants.CONTRACT_TIERS_URL, {contract_id: ''}) + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixin::addContract", url)
            .catch(error => {
                console.error("ContractMixin::addContract => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        getContract: async function(contract_id) {
            const url = this.constructRoute(Constants.CONTRACT_TIERS_URL, {contract_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContract", url)
            .catch(error => {
                console.error("ContractMixin::getContract => ERROR", error)
                return null
            })
            if(result.retour) return result.retour[0]
            return null
        },

        loadSeasons: async function() {
            const url = Constants.SEASON_LIST_URL + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::loadSeasons", url)
            .catch(error => {
                console.error("ContractMixin::loadSeasons => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        getSeasonStallions: async function(season_id) {
            const url = this.constructRoute(Constants.STALLION_SEASON_URL, {season_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::addContract", url)
            .catch(error => {
                console.error("ContractMixin::getSeasonStallions => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        loadAllModel: async function(modelable_id) {
            const url = this.constructRoute(Constants.MODEL_URL, {modelable_id: modelable_id, modelable_type: 'contract_config'}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ModelMixin::loadAllModel", url)
            .catch(error => {
                console.error("ModelMixin::loadAllModel => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        getContractsConfigByStallionAndSeason: async function(horse_id, season_id) {
            const url = this.constructRoute(Constants.HORSE_CONTRACT_CONFIG_URL, {horse_id: horse_id, season_id: season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContractsConfigByStallionAndSeason", url)
            .catch(error => {
                console.error("ContractMixin::getContractsConfigByStallionAndSeason => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        getContractConfigTypeMonte: async function(contractconfig_id) {
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_TYPE_MONTE_URL, {contractconfig_id: contractconfig_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContractsConfigByStallionAndSeason", url)
            .catch(error => {
                console.error("ContractMixin::getContractsConfigByStallionAndSeason => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        saveContract: async function(contract_id, form) {
            const url = this.constructRoute(Constants.CONTRACT_TIERS_URL, { contract_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixin::saveContract", url, { form })
            .catch(error => {
                console.error("ContractMixin::saveContract => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        getContractConfigTypeMonteFromId: async function(typemonte_id) { 
            const url = this.constructRoute(Constants.CONTRACT_TYPE_MONTE_URL, {typemonte_id: typemonte_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContractConfigTypeMonteFromId", url)
            .catch(error => {
                console.error("ContractMixin::getContractConfigTypeMonteFromId => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        printContract: async function(contract_id, return_stream = false) {           
            const url = this.constructRoute(Constants.CONTRACT_PRINT_URL, { contract_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ContractMixin::printContract", url)
			.catch(error => {
				console.error("ContractMixin::printContract => ERROR", error)
				return null
			})
            if(return_stream){
                return result
            }
            Common.base64ToPdf(result, "contract_" + contract_id + ".pdf")
            return true
		},

        contractAutoInvoice: async function(contract_id, contract_invoice_mode) {
            const url = this.constructRoute(Constants.CONTRACT_AUTO_INVOICE_URL, { contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_post_api("ContractMixin::saveContract", url, {mode: contract_invoice_mode }, false)
                .then(res => res.retour)
                .catch(e => {
                    console.error("ContractMixin::contractAutoInvoice => ERROR", e)
                    return null
                })
        },

        contractInvoiceMode: async function() {
            const url = Constants.CONTRACT_INVOICE_MODE_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("ContractMixin::contractAutoInvoice", url)
                .then(res => res.retour)
                .catch(error => {
                    console.error("ContractMixin::contractAutoInvoice => ERROR", error)
                    return null
                })
        },

        contractPrivacyPolicy: async function() {
            const url = Constants.CONTRACT_PRIVACY_POLICY_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("ContractMixin::printContract", url)
                .then(res => res.retour)
                .catch(error => {
                    console.error("ContractMixin::printContract => ERROR", error)
                    return null
                })
        },

        getContractConfigDefaultCmep: async function(contractconfig_id) {
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_DEFAULT_CMEP, {contractconfig_id: contractconfig_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContractConfigDefaultCmep", url)
            .catch(error => {
                console.error("ContractMixin::getContractConfigDefaultCmep => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        getMostUsedCmep: async function() {
            const url = Constants.CONTRACT_MOST_USED_CMEP + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContractConfigDefaultCmep", url)
            .catch(error => {
                console.error("ContractMixin::getContractConfigDefaultCmep => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        generateContractSignatureLink: async function(contract_id) {
            const url = this.constructRoute(Constants.CONTRACT_SIGNATURE_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::generateContractSignatureLink", url)
            if(result) return result.retour
            return null
        },
	}
}

export default ContractMixin
