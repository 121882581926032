import Vue from 'vue'
import Constants from 'Constants'
import RootTiers from 'GroomyRoot/mixins/Tiers.js'

export default Vue.util.mergeOptions(RootTiers, {
    methods: {
        loadTiersLite: async function() {
			const url = Constants.TIERS_LITE_URL + "?licence_key=" + Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("TiersMixin::loadTiersLite", url)
                .then(res => res.retour)
                    .catch(error => {
                        console.error("TiersMixin::loadTiersLite => ERROR", error)
                        return null
                    })
		},

        loadTiersWithCountry: async function() {
            const url = Constants.TIERS_LITE_COUNTRY_URL + "?licence_key=" + Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("TiersMixin::loadTiersWithCountry", url)
                .then(res => res.retour)
                .catch(error => {
                    console.error("TiersMixin::loadTiersWithCountry => ERROR", error)
                    return null
                })
        },

        loadCmepTiersWithCountry: async function(country_code = 75, postal_code = 0) {
            const params = {
                country_code: country_code,
                postal_code: postal_code
            }

            const url = this.constructRoute(Constants.TIERS_CMEP_LITE_COUNTRY_URL, params) + "?licence_key=" + Constants.USER_LICENCE_KEY

            return this.$request.request_get_api("TiersMixin::loadCmepTiersWithCountry", url)
                .then(res => res.retour)
                .catch(error => {
                    console.error("TiersMixin::loadCmepTiersWithCountry => ERROR", error)
                    return null
                })
        },

        loadPostalCodesCmep: async function() {
            const url = this.constructRoute(Constants.TIERS_POSTAL_CODES_CMEP, {}) + "?licence_key=" + Constants.USER_LICENCE_KEY

            return this.$request.request_get_api("TiersMixin::loadPostalCodesCmep", url)
                .then(res => res.retour)
                .catch(error => {
                    console.error("TiersMixin::loadPostalCodesCmep => ERROR", error)
                    return null
                })
        },

        loadCountriesCmep: async function() {
            const url = this.constructRoute(Constants.TIERS_COUNTRIES_CMEP, {}) + "?licence_key=" + Constants.USER_LICENCE_KEY

            return this.$request.request_get_api("TiersMixin::loadCountriesCmep", url)
                .then(res => res.retour)
                .catch(error => {
                    console.error("TiersMixin::loadCountriesCmep => ERROR", error)
                    return null
                })
        },

		loadLoggedTiers: async function() {
			const url = Constants.TIERS_LOGGED_URL + "?licence_key=" + Constants.USER_LICENCE_KEY

            return this.$request.request_get_api("TiersMixin::loadLoggedTiers", url)
                .then(res => res.retour)
                .catch(error => {
                    console.error("TiersMixin::loadLoggedTiers => ERROR", error)
                    return null
                })
		},

		editTiers: async function(params) {
			const url = this.constructRoute(Constants.TIERS_EDIT_URL, {tiers_id: params.tiers_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY

            return this.$request.request_post_api("TiersMixin::editTiers", url, params, false)
                .then(res => res.retour)
                .catch(error => {
                    console.error("TiersMixin::editTiers => ERROR", error)
                    return null
                })
		}
    }
})