<template>
	<div>
		<List :mare_id="horse.horse_id" />
	</div>
	<!-- <div>
		<div class="container-fluid" v-if="table">
			<div class="row">
				<div class="col-12">
					<div class="box">
						<CustomTable
							id_table="contract_mare_client_area"
							sortBy="contract_num"
							:busy.sync="table_busy"
							:items="contracts"
							primaryKey="contract_num"
							:hide_if_empty="true"
							:rawColumns="rawColumnNames"
							ref="contractTable"
							:hasExportOption="false"
							:showPagination="false"
						/>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<ContractShutter :contract_id="contract_id" :mare_id="horse.horse_id" ></ContractShutter>
		</div>

		<TiersModal ref="tiers_modal" @submit="tiersInfosChecked" />
	</div> -->
</template>

<script type="text/javascript">
	import { EventBus } from 'GroomyRoot/assets/js/event-bus.js'
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import Common from '@/assets/js/common.js'
	import Vue from 'vue'
	import TiersMixin from "@/mixins/Tiers.js"

	export default {
		name: "ContractMare",
		mixins: [TableAction, Navigation, ContractMixin, TiersMixin],
		props: ['horse'],
		data () {
			return {
				// table_busy: false,
				// table: true,
				// contract_id: null,
				// contracts: [],
				// events_tab: {
				// 	'TableAction::goToAddContract': async () => {
				// 		await this.checkInfosTiers()
				// 	},
				// 	'TableAction::goToEditContract': (params) => {
				// 		this.contract_id = parseInt(params.contract_id);
				// 		this.table = false
				// 	},
				// 	'TableAction::goToPrintContract': (params) => {
				// 		this.callPrintContracts(parseInt(params.contract_id))
				// 	},
				// 	'TableAction::goToSendContract': (contract_ids) => {
				// 		this.callSendContracts(contract_ids)
				// 	},
				// 	'TableAction::goToDeleteContract': (contract_ids) => {
				// 		this.deleteContract(contract_ids)
				// 	}
				// },
				// rawColumnNames: [
				// 	"article"
				// ]
			}
		},
		mounted() {
			// this.init_component()
			// EventBus.$on("Swipper::TabClicked", (params) => {
			// 	if(params.href == "ContractMareList" && !this.table_busy)
			// 	{
			// 		this.table = true
			// 		// this.$refs.contractTable.refreshTable()
			// 	}
			// })

			// EventBus.$on("ContractMareList::reloadTabs", (params) => {
			// 	if(params.href == "ContractMareList" && !this.table_busy)
			// 	{
			// 		this.table = true
			// 		// this.$refs.contractTable.refreshTable()
			// 	}
			// })
		},
		methods: {
			// async init_component() {
			// 	this.table_busy = true
			// 	this.table = true
			// 	this.contract_id = null

			// 	const contracts = await this.getContractByTiersAndHorse(this.horse.horse_id)
			// 	this.contracts = this.contracts_formatted(contracts)

			// 	this.table_busy = false
			// },
			// async checkForm() {
			// 	if(!this.processing) {
			// 		this.processing = true
			// 	}
			// },
			// async deleteContract(contract_ids) {
			// 	try {
			// 		await this.deleteContracts(contract_ids)
			// 	}
			// 	catch {
			// 		EventBus.$emit('App::failureToast', 'error.DC')
			// 	}
				
			// 	this.$refs.contractTable.refreshTable()
			// },
			// async callSendContracts(contract_ids) {
			// 	let result = await this.sendMailContracts(contract_ids)
			// 	if(result != null){
			// 		this.successToast('monte.send_success')
			// 	}
			// 	else
			// 	{
			// 		this.failureToast('monte.send_error')
			// 	}
			// 	EventBus.$emit("TableAction::stopSpin")
			// },
			// async callPrintContracts(contract_id) {
			// 	let result = await this.printContract(parseInt(contract_id))
			// 	if(result == null){
			// 		this.failureToast('monte.print_error')					
			// 	}
			// 	EventBus.$emit("TableAction::stopSpin")
			// },
			// back_action(){
			// 	this.table = true
			// 	if (this.$refs.contractTable) {
			// 		this.$refs.contractTable.refreshTable()
			// 	}
			// },
			// contracts_formatted(contracts) {
			// 	let res = []
			// 	contracts.forEach(async contract => {
			// 		let article = ""
			// 		for (let i = 0; i < contract.avenant_articles.length; i++) {
			// 			const ht = await Common.priceFormat(contract.avenant_articles[i].avenantarticles_ht / 100)
			// 			if (contract.avenant_articles[i].condition) {
			// 				article += this.getTrad(contract.avenant_articles[i].condition.conditions.contractconditions_label) + " : " + ht + "<br>"
			// 			}
			// 		}
			// 		res.push({
			// 			contract_id: contract.contract_id,
			// 			contract_num: (contract.contract_num == null) ? Vue.i18n.translate("monte.contrat_brouillon_non_numerote") : contract.contract_num,
			// 			tiers_rs: contract.tiers_rs,
			// 			tiers_id: contract.tiers_id,
			// 			mare_nom: contract.mare_nom,
			// 			mare_id: contract.mare_id,
			// 			stallion_id: contract.stallion_id,
			// 			stallion_nom: contract.stallion_nom,
			// 			cmep: contract.cmep,
			// 			avenant_date_signature: contract.avenant_date_signature,
			// 			season_label: contract.season_label,
			// 			article: article,
			// 			avenant_status:this.getTrad(contract.avenant_status)
			// 		})
			// 	})
			// 	return res
			// },

			// async checkInfosTiers() {
			// 	let logged_tiers = await this.loadLoggedTiers()
			// 	this.$refs.tiers_modal.openModal(logged_tiers)
			// },
			// async tiersInfosChecked(infos_tiers_filled) {
			// 	if(infos_tiers_filled) {
			// 		const contract = await this.addContract()
			// 		this.contract_id = contract.contract_id
			// 		this.table = false
			// 	}
			// }
		},
		computed: {
			
		},
		watch: {
			// $route(){
			// 	this.table = true
			// }
		},
		components: {
			HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ContractForm : () => import('@/components/Contract/ContractForm'),
			ContractList : () => import('@/components/Contract/ContractList'),
			ContractShutter : () => import('@/components/Contract/ContractShutter'),
			TiersModal: () => import('@/components/Contract/InformationsTiersForm'),
			List : () => import('@/components/Contract/List')
		},
	}
</script>
